/* eslint-disable no-console,no-undef,camelcase */
/* eslint-disable react/no-unknown-property */
import React, { Component, lazy } from 'react';
import PropTypes from "prop-types";
import { convertDateInTimezone, getStatus, getTimeToShow, youTubeGetID} from "../../../utils/methods";
import moment from "moment";
import { joinOrLeaveChallenge, getInviteAmigosList, getUserListOfChallenges, getBuddiesListOfChallenges } from "../../../redux/actions";
import { connect } from "react-redux";
import { RecipeContainer, MyTaskContainerV2, ViewContainerV2, VideoContainerV2, TodayTaskButton, TodayTaskContainerV2, ButtonContainerNew, ButtonsNew } from './styles';
import Image from "../../Image";
import { toast } from "react-toastify";
import { isNull, isUndefined } from "lodash";
const vimeoUrl = "https://vimeo.com/api/oembed.json?url=";
import { TaskContainer, EventsButton } from '../styles';
import { withTranslation } from 'react-i18next';
// const DemoPopup = lazy(() => import('../../WhyAmigoCommon/ChallengePopup'));
import DemoPopup from '../../WhyAmigoCommon/ChallengePopup';
const ParticipantsPopUp = lazy(() => import('../participantsInChallengePopUp'));
const InviteAmigoPopUp = lazy(() => import('../InviteAmigoPopUp'));


class RightSideSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: [],
      isChallengeLocked: getStatus(props.challenge),
      activeDate: moment(moment()._d, 'MM DD YYYY').format('MM-DD-YYYY'),
      showStartChallenge: false,
      showCompletedStatus: true,
      showInviteAmigoPopup: false,
      valid: false,
      joinOrLeave: 0,
      dailyObj: 0,
      daysLeft: 0,
      currentData: false,
      showVideo: false,
      showParticipants: false,
      dynamicImageUrl: '',
      printChallengeCSV: false,
      updatedChallengeCSV: {},
    }
  }
  componentDidMount() {
    const { challenge, selectedDate, liveChallengeStatus } = this.props;
    this.setState({
      time: getTimeToShow(challenge, 'challenge'),
      activeDate: selectedDate
    }, () => !liveChallengeStatus && this.daysDetails(this.state.time, this.state.activeDate))

    if (challenge.introduction_video) {
      if (!isNull(challenge.introduction_video) && !isUndefined(challenge.introduction_video) && challenge.introduction_video.includes("vimeo.com")) {
        const headers = {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, PATCH, OPTIONS",
          "Access-Control-Allow-Headers": "X-Requested-With, content-type, Authorization"
        };
        let url = `${vimeoUrl}${challenge.introduction_video}`;
        fetch(url, headers)
          .then((res) => res.json())
          .then((res) => {
            this.setState({ dynamicImageUrl: res.thumbnail_url })
          })
          .catch(() =>
            toast.error("Can’t access response!")
          );
      }
      if ((!isNull(challenge.introduction_video) && !isUndefined(challenge.introduction_video)) && challenge.introduction_video.includes("youtu")) {
        const getYoutudeId = youTubeGetID(challenge.introduction_video)
        let youtudeImageUrl = `https://img.youtube.com/vi/${getYoutudeId}/0.jpg`
        this.setState({ dynamicImageUrl: youtudeImageUrl })
      }
    }

    // getUserListOfChallenges(challenge.id);
    getBuddiesListOfChallenges(challenge.id, 1);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.challenge !== this.props.challenge) {
      this.setState({
        time: getTimeToShow(this.props.challenge, 'challenge'),
        activeDate: this.props.selectedDate
      }, () => !this.props.liveChallengeStatus && this.daysDetails(this.state.time, this.state.activeDate))
    }
  }
  daysDetails = (time, activeDate) => {
    const { challenge, setObjIndex, objIndex, taskUpdated, selectedDate } = this.props;
    let challengeOver = true;
    time.map((step) => {
      const values = step.split(' ');
      if (values && parseInt(values[0],10) > 0) {
        challengeOver = false;
      }
    });
    const valid = convertDateInTimezone(challenge.end_date)._d >= moment()._d;
    const joinOrLeave = !challengeOver && (challenge.challenge_status === 'joined' ? 0 : 1);
    let today = moment()._d;
    let startDay = convertDateInTimezone(challenge.start_date)._d;
    let endDate = convertDateInTimezone(challenge.end_date)._d;
    let arr = [];
    let index = 0;
    let currentLength = moment(today, 'MM DD YYYY').diff(startDay, 'days');
    if (currentLength >= 0) {
      while (index <= (currentLength + 1)) {
        let dateObj = moment(startDay, 'MM DD YYYY').add(index, 'days').format('MM-DD-YYYY');
        arr.push(dateObj);
        index++;
      }
    }
    let selectedDay = taskUpdated ? !selectedDate ? moment(moment()._d, 'MM DD YYYY').format('MM-DD-YYYY') : selectedDate : (activeDate == "Invalid date" || !activeDate ? moment(moment()._d, 'MM DD YYYY').format('MM-DD-YYYY'): activeDate);
    
    let objDayIndex = taskUpdated ? objIndex : arr.findIndex((date) => selectedDay == date);
    let dailyObj = (objDayIndex >= 0) ? challenge?.behavior_challenge_details?.[objDayIndex] : {};
    let daysLeft = moment(endDate, 'MM DD YYYY').diff(today, 'days');
    let currentData = selectedDay == moment(today, 'MM DD YYYY').format('MM-DD-YYYY');
    setObjIndex(objDayIndex);
    
    this.setState({ valid: valid, joinOrLeave: joinOrLeave, dailyObj: dailyObj, selectedDay: daysLeft, startDay: startDay, currentData: currentData });
  }

  showProfileImage = () => {
    const { profileImage } = this.props;
    const { isUpdatedImage } = this.state;
    if (isUpdatedImage) {
      return (<img src={profileImage} alt="avatar" />)
    }
    else {
      return (<Image image={profileImage} alt="avatar" />)
    }
  };
  joinChallenge = (challengeId, isJoined) => {
    const { joinOrLeaveChallenge } = this.props;
    const data = {
      challenge_id: challengeId,
      is_joined: isJoined
    };
    joinOrLeaveChallenge(data);
  };
  updateDailyTaskAction = (bool, index) => {
    window.console.log("index",index);
    const { updateDailyTask, challenge, setObjIndex, objIndex } = this.props;
    let obj = {
      id: challenge.id,
      task_completed: bool,
      day_key: `day_${index}`
    };
    updateDailyTask(obj);
    setObjIndex(objIndex, 1, this.state.activeDate);
  };
  ShowHideChallengePopup = () => {
    this.setState((prevState) => ({
      showStartChallenge: !prevState.showStartChallenge
    }))
  };
  updateStatus = () => {
    this.setState((prevState) => ({
      showCompletedStatus: !prevState.showCompletedStatus
    }))
  };
  showHideInviteAmigoPopup = () => {
    this.setState((prevState) => ({
      showInviteAmigoPopup: !prevState.showInviteAmigoPopup
    }), () => this.props.getInviteAmigosList(this.props.userId, this.props.challenge.id))
  };

  changeModal = () => {
    this.setState((prevState) => ({
      showVideo: !prevState.showVideo
    }));
  }
  changeParticipantsModal = () => {
    this.setState((prevState) => ({
      showParticipants: !prevState.showParticipants
    }));
  }
  previousHandler = () => {
    let prevDay = moment(this.state.activeDate, 'MM-DD-YYYY').subtract(1, 'day').format('MM-DD-YYYY');
    this.setState({ activeDate: prevDay == "Invalid date" ? moment(moment()._d, 'MM DD YYYY').subtract(1, 'day').format('MM-DD-YYYY') : prevDay }, () => this.daysDetails(this.state.time, this.state.activeDate));
  }

  nextHandler = () => {
    let NextDay = moment(this.state.activeDate, 'MM-DD-YYYY').add(1, 'day').format('MM-DD-YYYY');
    this.setState({ activeDate: NextDay }, () => this.daysDetails(this.state.time, this.state.activeDate));
  }

  render() {
    const { challenge, isDummyChallenge, objIndex, challengeAttendees, amigosList, userId, role, t } = this.props;
    const { showCompletedStatus, dailyObj, startDay, currentData, showVideo, showParticipants } = this.state;
    let today = moment()._d;
    return (
      <MyTaskContainerV2>
        {(challenge && (today > startDay) && challenge.is_joined && challenge.challenge_status !== 'over') ?
          (!isUndefined(dailyObj) &&!isUndefined(dailyObj['video_url']) && dailyObj['video_url'] === null) ?
            <RecipeContainer>
              <ButtonContainerNew>
                <ButtonsNew Textcolor="#fff" onClick={() => this.previousHandler()} disabled={objIndex === 0}>
                  <i className="fas fa-chevron-left" />
                </ButtonsNew>
                <ButtonsNew onClick={() => this.nextHandler()} disabled={currentData} Textcolor="#fff">
                  <i className="fas fa-chevron-right" />
                </ButtonsNew>
              </ButtonContainerNew>
              <VideoContainerV2 padding={"20px 0 20px 0px"}>
                <div>Day {objIndex + 1}: {"-"}</div>
              </VideoContainerV2>
            </RecipeContainer> :
            (challenge && (today > startDay) && challenge.is_joined && challenge.challenge_status !== 'over') && !isUndefined(dailyObj) && !isUndefined(dailyObj['video_url']) &&(dailyObj['video_url'] !== null) ?
              (
                <ViewContainerV2>
                  <RecipeContainer>
                    {(challenge && (today > startDay) && challenge.is_joined && challenge.challenge_status !== 'over') ?
                      <ButtonContainerNew>
                        <ButtonsNew Textcolor="#fff" onClick={() => this.previousHandler()} disabled={objIndex === 0}>
                          <i className="fas fa-chevron-left" />
                        </ButtonsNew>
                        <ButtonsNew onClick={() => this.nextHandler()} disabled={currentData} Textcolor="#fff">
                          <i className="fas fa-chevron-right" />
                        </ButtonsNew>
                      </ButtonContainerNew> : null}
                    <VideoContainerV2>
                      <div>{t("Day")} {objIndex + 1}: {dailyObj['video_title']}</div>
                      <div>
                        {!challenge.behavior_challenge_details[objIndex == -1 ? 0 : objIndex].task_completed ? <TodayTaskButton onClick={() => this.changeModal()}>
                          {t("Start Today's Task")}
                        </TodayTaskButton> : <TodayTaskButton style={{cursor:"default"}}>
                          {t("Task is already completed")}
                        </TodayTaskButton>}
                      </div>
                    </VideoContainerV2>
                    {showVideo && <DemoPopup showModal={showVideo} onClose={this.changeModal} videoUrl={dailyObj['video_url']} VideoTitle={dailyObj['video_title']} />}
                  </RecipeContainer>
                </ViewContainerV2>) :
              (!isUndefined(dailyObj)&&
                <RecipeContainer>
                  <ButtonContainerNew>
                    <ButtonsNew Textcolor="#fff" onClick={() => this.previousHandler()} disabled={objIndex === 0}>
                      <i className="fas fa-chevron-left" />
                    </ButtonsNew>
                    <ButtonsNew onClick={() => this.nextHandler()} disabled={currentData} Textcolor="#fff">
                      <i className="fas fa-chevron-right" />
                    </ButtonsNew>
                  </ButtonContainerNew>
                  <VideoContainerV2 padding={"20px 0 20px 0px"}>
                    <div>Day {objIndex + 1}: {dailyObj['video_title']}</div>
                  </VideoContainerV2>
                </RecipeContainer>
              ) :
          null}

        {(challenge && (today > startDay) && challenge.is_joined && challenge.challenge_status !== 'over') && !isUndefined(dailyObj) ? <TodayTaskContainerV2>
          <div>
            {`${t("Today's Task for Day")} ${objIndex + 1}`}
          </div>
          <div>{dailyObj['about_the_day']}</div>
        </TodayTaskContainerV2> : null}
        {(challenge && (today > startDay) && challenge.is_joined && challenge.challenge_status !== 'over')&& !isUndefined(challenge.behavior_challenge_details) && !isUndefined(challenge.behavior_challenge_details[objIndex == -1 ? 0 : objIndex]) ?
          <TaskContainer noButton={isDummyChallenge ? showCompletedStatus : challenge && challenge.behavior_challenge_details && !challenge.behavior_challenge_details[objIndex == -1 ? 0 : objIndex].task_completed} >
            <div>{t("Did you complete today’s task?")}</div>
            <div><div>
              {isDummyChallenge ? showCompletedStatus : challenge && challenge.behavior_challenge_details && !challenge.behavior_challenge_details[objIndex == -1 ? 0 : objIndex].task_completed ?
                <button noButton={isDummyChallenge ? showCompletedStatus : challenge && challenge.behavior_challenge_details && !challenge.behavior_challenge_details[objIndex == -1 ? 0 : objIndex].task_completed}
                  onClick={() => isDummyChallenge ? this.updateStatus() : this.updateDailyTaskAction(1, objIndex)}>
                  {t("Yes")}
                </button> :
                <button style={{cursor:"default"}} noButton={isDummyChallenge ? showCompletedStatus : challenge && challenge.behavior_challenge_details && !challenge.behavior_challenge_details[objIndex == -1 ? 0 : objIndex].task_completed}
                  onClick={() => isDummyChallenge && this.updateStatus()}>
                  {t("Today's Task Completed")}
                </button>}
            </div>
            </div>
          </TaskContainer> : null}
        {((role === "ADMIN" || role === "WELLNESS_ADMIN"|| challenge.created_by === userId) && challenge.is_user_challenge===1) && challenge.challenge_status !== 'over' && challenge.challenge_status === 'joined' && <EventsButton backgroundColor={"rgb(253, 113, 117)"} color={"#fff"} padding={'13px 8px 13px 8px'} font={"18px"} onClick={() => this.showHideInviteAmigoPopup()}>
          {t("Invite Buddies")}
        </EventsButton>}
        <ParticipantsPopUp
          showModal={showParticipants}
          onClose={this.changeParticipantsModal}
          challengeAttendees={challengeAttendees}
          amigosList={amigosList}
        />
        <InviteAmigoPopUp userId={userId} history={this.props.history} showModal={this.state.showInviteAmigoPopup} onHide={this.showHideInviteAmigoPopup} challengeId={challenge.id} plusAmigoButton={1} />
      </MyTaskContainerV2>
    );
  }
}
RightSideSection.propTypes = {
  challenge: PropTypes.object.isRequired,
  location: PropTypes.object,
  userId: PropTypes.number.isRequired,
  profileImage: PropTypes.string.isRequired,
  joinOrLeaveChallenge: PropTypes.func,
  history: PropTypes.object,
  user: PropTypes.object,
  updateDailyTask: PropTypes.func,
  liveChallengeStatus: PropTypes.bool,
  isDummyChallenge: PropTypes.bool,
  showHideInviteAmigoPopup: PropTypes.func,
  getInviteAmigosList: PropTypes.func,
  setObjIndex: PropTypes.func,
  objIndex: PropTypes.number,
  taskUpdated: PropTypes.number,
  selectedDate: PropTypes.string,
  inviteAmigoPopUp: PropTypes.func,
  handleChallengeID: PropTypes.func,
  challengeAttendees: PropTypes.array,
  getUserListOfChallenges: PropTypes.func,
  getBuddiesListOfChallenges: PropTypes.func,
  amigosList: PropTypes.array,
  userPermissions: PropTypes.array,
  role: PropTypes.string,
  t: PropTypes.func
};
const mapStateToProps = (state) => ({
  user: state.profileData.user,
  isDummyChallenge: state.challenges.isDummyChallenge,
  challengeAttendees: state.challenges.challengeAttendees,
  amigosList: state.challenges.amigosList,
});
const mapDispatchToProps = (dispatch) => ({
  joinOrLeaveChallenge: (data) => dispatch(joinOrLeaveChallenge(data)),
  getInviteAmigosList: (userID, challengeId) => dispatch(getInviteAmigosList(userID, challengeId)),
  getUserListOfChallenges: (challengeId) => dispatch(getUserListOfChallenges(challengeId)),
  getBuddiesListOfChallenges: (challengeId, status) => dispatch(getBuddiesListOfChallenges(challengeId, status)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RightSideSection));