import React, {Component} from 'react';
import {MyProgressWrapper, DailyProgressMain, DailyProgressFirst, DailyProgressLast, ProgressLabelsV2, LabelsContainerV2, DaysNew, ToalDaysCard, ParticipantCount } from "../MyTaskTab/styles";
import PropTypes from "prop-types";
import moment from "moment";
import {convertDateInTimezone} from "../../../utils/methods";
import {NoComments} from "../../SocialFeeds/styles";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const labels = [{
  color: 'linear-gradient(90deg,rgb(0,177,188) -1.11%,rgb(117,217,163) 97.46%)',
  text: 'Completed'
},{
  color: '#FD7175',
  text: 'Not Completed'
},{
  color: '#69c2ff',
  text: "Today's"
}
];

class MyProgressTab extends Component{

  constructor(props) {
    super(props);
    this.state = {
      behaviourChallengeDetails : props.challenge['behavior_challenge_details'],
      currentDate: moment(moment()._d, 'MM DD YYYY').format('MM-DD-YYYY')
    }}

  dayStatus = () => {
    const{behaviourChallengeDetails, currentDate} = this.state;
    const{challenge} = this.props;
    let startDay = convertDateInTimezone(challenge.start_date)._d;
    let arr = [];
    let index = 0;
    let currentLength = behaviourChallengeDetails.length;
    if(currentLength > 0) {
      while(index < currentLength) {
        let dateObj = moment(startDay, 'MM DD YYYY').add(index, 'days').format('MM-DD-YYYY');
        arr.push(dateObj);
        index++;
      }}
    return behaviourChallengeDetails.map((data, index) => <DaysNew key={`date-${index}`} fontSize="16px" done={data['task_completed']} currentDay={arr[index] == currentDate} nextDays={arr[index] > currentDate ? 1 : 0}>{index+1}</DaysNew>)
  };

  render() {
    const{challenge, t, challengeAttendees} = this.props;
    let today = moment()._d;
    let startDay = convertDateInTimezone(challenge.start_date)._d;
    return (
      challenge && (today > startDay) && (challenge.is_joined) ?
        <MyProgressWrapper>
          <DailyProgressMain>
            <div>
              <DailyProgressFirst>
                <div>
                  <span>{t("Daily Progress")}</span>
                </div>
                <div>
                  <LabelsContainerV2>
                    {
                      labels.map((data, index) => (
                        <ProgressLabelsV2 key={index} bgColor={data['color']}>
                          <div/>
                          <div>
                            {this.props.t(data['text'])}
                          </div>
                        </ProgressLabelsV2>
                      ))}
                  </LabelsContainerV2>
                </div>
              </DailyProgressFirst>
            </div>
            <div>
              <DailyProgressLast>
                <div>
                  {this.dayStatus()}
                </div>
              </DailyProgressLast>
            </div>
          </DailyProgressMain>
          <ToalDaysCard color={"1"}>
            <div>
              <div>
                <img src="/public/images/NewDashboardV2/perfectDays.png" alt="back-arrow" />
              </div>
              <div>
                {t("Total Perfect Days")}
              </div>
            </div>

            <div>
              {challenge && challenge['total_perfect_days']}
            </div>
          </ToalDaysCard>
          <ToalDaysCard >
            <div>
              <div>
                <img src="/public/images/NewDashboardV2/longest_streak.png" alt="back-arrow" />
              </div>
              <div>
                {t("Total Longest Streak")}
              </div>
            </div>

            <div>
              {challenge && challenge['longest_streak']}
            </div>
          </ToalDaysCard>
        </MyProgressWrapper>:
        (challenge.challenge_status == 'running' || challenge.challenge_status == 'locked') ? <NoComments>{this.props.t(`You haven't joined the challenge`)}</NoComments> : (challenge.challenge_status === 'over') ? <NoComments>{t("This challenge has completed")}</NoComments> : 
          <ParticipantCount color="#F6B479" background="linear-gradient(180deg,#1E6481 0%,#0D425C 60.94%,#022C43 100%)">
            <div>{t("Total Participants")}</div>
            <div>{challengeAttendees && challengeAttendees.length}</div>
          </ParticipantCount >
    );}}
    
MyProgressTab.propTypes = {
  challenge: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  profileImage: PropTypes.string.isRequired,
  t: PropTypes.func,
  challengeAttendees: PropTypes.array
};

const mapStateToProps = (state) => ({
  challengeAttendees: state.challenges.challengeAttendees,
});

export default connect(mapStateToProps, null)(withTranslation()(MyProgressTab));
      